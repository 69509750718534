import React from "react"
import PropTypes from "prop-types"
import {  StyledHeader, Title, Subheading, FocusSpan} from "./style.js"


export default function Header({ location }) {
  //size of header and whether subheading included varies based on route location
  const homeOrEndRoute = location.pathname.includes("home") || location.pathname.includes("end")
  const homeRouteExact = location.pathname === ("/home")

  return (
    <StyledHeader large={homeOrEndRoute} subheading={homeRouteExact}>
      <a href="https://www.trinkspiel-online.de" style={{textDecoration: "none",color: "#1982c4",textAlign:"center"}}>← zurück zur Hauptseite</a>
      <Title large={homeOrEndRoute}><a href="https://www.trinkspiel-online.de" style={{textDecoration: "none",color: "#1982c4"}}>Buzzer Game!</a><br></br> <span style={{backgroundColor:"#AECE7C",fontSize:"50%",padding:"0px 10px", color:"#282828"}}>Trinkspiel online</span></Title>
      {homeRouteExact && (
        <Subheading>
         Jede Menge {" "}
          <FocusSpan>Spielspass,</FocusSpan> online mit deinen {" "}
          <FocusSpan>Freunden</FocusSpan>.
        </Subheading>
      )}
    </StyledHeader>
  )
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
}
