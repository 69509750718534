import { LayeredTabooCard } from 'components/shared/TabooCard'
import React from 'react'

const LayeredCards = () => {
	return (
		<React.Fragment>
			<LayeredTabooCard layer="top" tabooWord="Buzzer!" list={['Buzzer!', 'Buzzer!', 'Buzzer!', 'Buzzer!']} />
			<LayeredTabooCard layer="middle" tabooWord="Buzzer!" list={['Buzzer!', 'Buzzer!', 'Buzzer!', 'Buzzer!']} />
			<LayeredTabooCard layer="bottom" tabooWord="Buzzer!" list={['Buzzer!', 'Buzzer!', 'Buzzer!', 'Buzzer!']} />
		</React.Fragment>
	)
}

export default LayeredCards
