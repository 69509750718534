/* Randomize array in-place using Durstenfeld shuffle algorithm */
export const shuffleArray = (array) => {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1))
		var temp = array[i]
		array[i] = array[j]
		array[j] = temp
	}

	return array
}

export const convertArrayToObject = (array) => {
	const convertedObject = array.reduce((updatedObject, element, index) => {
		return {
			...updatedObject,
			[index]: element,
		}
	}, {})
	return convertedObject
}

export const generateSkipText = (skipPenalty) => {
	let skipText
	switch (skipPenalty) {
		case 'half':
			skipText = '1/2 Punkt'
			break
		case 'full':
			skipText = '1 Punkt'
			break
		default:
			skipText = 'kein Punkt'
			break
	}

	return `Jede richtige Karte ist für dein Team 1 Punkt wert, für jede übersprungene Karte erhält das andere Team ${skipText}. Wenn der Buzzer gedrückt wird, erhält das andere Team 1 Punkt.\n`
}

export const generateEndGameText = (endGameMethod, endGameValue) => {
	let endGameText
	if (endGameMethod === 'Runden') {
		endGameText = `Jeder Spieler hat ${endGameValue} abwechselnd Hinweise gegeben.`
	} else {
		endGameText = `${endGameValue} Minuten`
	}
	return `Die Runde endet nach ${endGameText} oder wenn alle Karten gespielt sind. Viel Glück! `
}

export const createGreetingMsg = () => {
	const line1 = '%cHi There!'
	const style1 = `font-size: 2.6rem; color: #1982C4; font-style:Palatino; letter-spacing: 2px`
	const line2 =
		"%cDanke für's Vorbeischauen."
	const style2 = `font-size: 1rem; color:#333333; font-style:Palatino`
	const line3 = ``
	const style3 = `font-size: 1rem; font-weight:bold; color:red; font-style:Palatino`

	console.log(line1 + line2 + line3, style1, style2, style3)
}
