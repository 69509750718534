import React from 'react'
import { StyledDisclaimer } from './style'

export const Disclaimer = () => {
	return (
		<StyledDisclaimer>
			
		</StyledDisclaimer>
	)
}
