import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4wnKPFF8CIMfkNk1FNkzsIpJJqWfVJ3s",
  authDomain: "premium-tabu-spiel.firebaseapp.com",
  databaseURL: "https://premium-tabu-spiel-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "premium-tabu-spiel",
  storageBucket: "premium-tabu-spiel.appspot.com",
  messagingSenderId: "481672275633",
  appId: "1:481672275633:web:dcbb11060be55e90eb6490",
  measurementId: "G-EMN02PH5E0"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

//Initialize firestore
firebase.firestore()

export default firebase
